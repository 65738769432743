import React, { useState } from "react"
import styled from "styled-components"
import { SectionTitle, AccordianRowHeading } from "../typography.js"

const Wrapper = styled.section`
  box-sizing: border-box;
  width: 80%;
  margin: 50px auto;
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
`
const RowWrap = styled.section`
  background-color: ${props => props.backColor};
  border-left: 3px solid ${props => props.borderColor};
  border-radius: 8px;
  margin: 10px 0;
`
const RowHeadingWrap = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: transparent;
  padding: 18px 50px;
  color: #444;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  &::before {
    content: "+";
    position: relative;
    left: -10px;
    font-size: 32px;
    color: #000;
    font-weight: 500;
    transition: transform 0.2s ease-in-out;
    transform: rotate(${props => (props.active ? "45deg" : "0deg")});
  }
  &:active,
  &:hover {
    background-color: transparent;
  }
`
const Panel = styled.div`
  display: ${props => (props.active ? "block" : "none")};
  transition: display 0.4s ease-out;
  padding: 0 50px;
  background-color: transparent;
  max-height: ${props => (props.active ? "none" : "0")};
  overflow: hidden;
`
const RowText = styled.p`
  font-size: 17px;
  font-weight: 500;
  opacity: 0.7;
  line-height: 1.556;
  &:before {
    content: "Ans: ";
  }
`

const AccordianRow = ({ row, active, index, onClick }) => {
  const { heading, body, accordianItems, imp } = row

  const backgroundColorPicker = importanceTag => {
    if (importanceTag === "critical") {
      return { sharp: "", sharp: "rgb(254,195,154)" }
    } else if (importanceTag === "neutral") {
      return { light: "rgb(255,246,238)", sharp: "rgb(254,195,154)" }
    } else if (importanceTag === "good") {
      return { sharp: "rgb(180,181,253)", light: "rgb(250,250,255)" }
    } else {
      return { sharp: null, light: null }
    }
  }
  const borderColor = backgroundColorPicker(imp).sharp
  const backColor = backgroundColorPicker(imp).light
  return (
    <RowWrap borderColor={borderColor} backColor={backColor}>
      <RowHeadingWrap
        onClick={() => onClick(!!active ? null : index)}
        active={active}
      >
        <AccordianRowHeading>{heading}</AccordianRowHeading>
      </RowHeadingWrap>
      <Panel active={active}>
        <RowText>{body}</RowText>
        {accordianItems ? (
          <Accordian_V1 wide accordianItems={accordianItems} />
        ) : null}
      </Panel>
    </RowWrap>
  )
}

export const AccordianList = ({ accordianItems, title }) => {
  //Use if you want just the list.
  const [activeAccord, setActiveAccord] = useState(null)

  return accordianItems.map((item, index) => (
    <AccordianRow
      key={`${title ? title : item.heading} Accordian ${index}`}
      active={activeAccord === index}
      index={index}
      onClick={setActiveAccord}
      row={item}
    />
  ))
}
const Accordian_V1 = ({ wide, title, accordianItems }) => {
  //Use if you want heading & full section
  //wide
  //title = string
  //accordianItems: Array of objects. Object takes: heading, body, importance, accordianItems
  return (
    <Wrapper
      style={
        wide
          ? { width: "100%", scale: "0.7", padding: "0px" }
          : { width: "null" }
      }
    >
      {title ? (
        <SectionTitle style={{ textAlign: "center", marginBottom: "50px" }}>
          {title}
        </SectionTitle>
      ) : null}
      <AccordianList accordianItems={accordianItems} title={title} />
    </Wrapper>
  )
}
export default Accordian_V1
