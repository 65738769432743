import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import SEO from "../../components/seo.js"
import Layout from "../../components/layout.js"
import { navigate } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import Accordian from "../../components/molecules/accordian.js"

import { InPageNav } from "../../components/navbar.js"

import {
  HeroTitleH1,
  SectionTitle,
  SectionDescription,
} from "../../components/typography.js"
import { Section } from "../../components/sections/sections.js"

const Row = styled(Section)`
  padding-top: 70px;
  padding-bottom: 70px;
`
const RowContent = styled.div`
  opacity: 0.1;
  transition: opacity 0.3s ease-in;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
`
const RowItemWrap = styled.div`
  margin: auto 0;
  position: relative;
  top: 15px;
  left: 15px;
`
const Title = styled(SectionTitle)`
  font-size: 500;
`

const NumberTitle = styled(Title)`
  display: block;
  font-size: 94px;
  font-weight: 600;
  position: relative;
  top: -15px;
  left: 15px;
`

const howClassWorksContent = [
  {
    title: "Getting Started",
    description:
      "Starting your course is easy. Once you're onboarded onto our platform, you will fill out a form that tells us about you.",
  },
  {
    title: "Getting Personal",
    description:
      "We hate to be bores, and our team wants to know you! We'll set up a personal chat between you and all the instructors so we can easily stay in touch! We do informal office hours, but in general you can ask a question anytime. We won't get back to you immediately, but our response times are pretty fast during course times.",
  },
  {
    title: "Configuring your timeline",
    description:
      "Our platform asks you for your test date -- don't worry if you don't have one -- we'll recommend a time you should write it. Based on this, we'll set up your deadlines, and schedule. You don't have to do anything! If we haven't as yet asked you when you're planning to write your test, this would it :) ",
  },
  {
    title: "Making Your Game-plan",
    description:
      "This is when we'll build the order in which you'll complete all your lectures and subjects -- usually, we start with CARS and then work our way into the sciences.",
  },
  {
    title: "Mindset Training",
    description:
      "Before we get started with the content, we get you in 'MCAT mode'. At this time, you'll learn about the number of hours you'll study, how to learn content, what medical school looks like, and how you're going to become the better you! We study for the MCAT as if we're _already_ in medical school - which means longer hours, and studying harder! So, get pumped!! :D ",
  },
  {
    title: "Mastering the content",
    description:
      "We don't give you access to all the content immediately -- it overwhelms people :) Instead, we'll give you a lecture to master -- when you know your stuff, you can take the lecture quiz to prove that you're ready to move on. When you pass the quiz, your next lecture literally unlocks! ",
  },
  {
    title: "Acing the quizzes",
    description:
      "We know exactly how to do well on the MCAT -- you need to know your content! To make sure that you don't just slip through the cracks, or move on when you're not ready, you will have to score 80% on your quiz to move on to the lecture. Just a heads-up: we've been students too ;) Our system knows when you're BS-ing, or clicking at random -- it'll catch you! #fullAccountability #noEscape ",
  },
  {
    title: "Mastering the Subjects",
    description:
      "Once you've completed your lectures, we check whether you're good to move on to the next quiz. Sometimes, you can pass all the lectures, but it may look like you didn't pass most of the quizzes on your first attempt. If so, we have to fix this before we can move on.",
  },
  {
    title: "Mock Examinations",
    description:
      "We use only the AAMC past tests to build your MCAT-competance and and to predict your scores. At this point, if you've done everything correctly -- worked the right number of hours, weren't absent during for a significant number of days, and did the content as we've asked of you, you'll be scoring near 131-132s across all sections. If this is not the case, our instructors will look at your test scores and identify your issues and give your further tasks. ",
  },
  {
    title: "The Day Before Your Test",
    description:
      "At this point, somtimes the only thing standing in the way is test anxiety; you know your stuff, but you're just worried about the test in-general. Although this is not officially a part of the course, if you've worked hard throughout the course, our instructors will give you a call the day before the test to share their insights and to give you a pep. talk! We like checking to make sure that you're not overthinking things or overtly worried when you shouldn't be :) ",
  },
  {
    title: "Post-Test Follow-up",
    description:
      "When you're back from the test, let us know how it went! We prep. for the test as if we're studying with you, and often we worry about you :') The follow-up is a way to detox post test, and if needed, plan for next steps - whether that's submitting applications to med. schools, studying for your CASPer test, or maybe at times rewriting the test.",
  },
]
const HowItWorks = ({ location }) => {
  const [positions, setPositions] = useState([])
  const [currentHighlight, setHighlight] = useState(0)
  const itemRefs = useRef([])

  useEffect(() => {
    let positionsArray = itemRefs.current.map((el, index) => {
      if (typeof window !== "undefined") {
        return window.pageYOffset + el.getBoundingClientRect().top
      } else {
        return 0
      }
    })
    setPositions(positionsArray)
  }, [])

  const handleRefs = (el, index) => {
    if (itemRefs.current.length < howClassWorksContent.length)
      itemRefs.current.push(el)
  }
  const positionLocator = (
    currPos,
    scrollingDownBool,
    upperRange,
    lowerRange
  ) => {
    positions.forEach((row, index) => {
      while (
        positions[index] &&
        positions[index] > upperRange &&
        positions[index] < lowerRange
      ) {
        setHighlight(index)
        itemRefs.current[currentHighlight].style.opacity = "1"
        if (
          index - 1 > -1 &&
          itemRefs.current[index - 1].style.opacity === "1"
        ) {
          itemRefs.current[index - 1].style.opacity = "0.1"
        }
        if (
          index + 1 < positions.length &&
          itemRefs.current[index + 1].style.opacity === "1"
        ) {
          itemRefs.current[index + 1].style.opacity = "0.1"
        }
        break
      }
    })
  }
  useScrollPosition(({ prevPos, currPos }) => {
    if (typeof window !== "undefined") {
      let currX = Math.abs(currPos.y)
      let prevX = Math.abs(prevPos.y)
      let upperRange =
        window.innerHeight * 0.3 || document.documentElement.clientHeight * 0.3
      let lowerRange =
        window.innerHeight * 0.7 || document.documentElement.clientHeight * 0.7
      // let currentItemPos = itemRefs.current[
      //   currentHighlight
      // ].getBoundingClientRect().top
      positionLocator(
        currX,
        currX > prevX,
        currX + upperRange,
        currX + lowerRange
      )
    }
  })
  const RedirectToPage = dest => {
    navigate(dest)
  }
  return (
    <Layout>
      <SEO title="How 99point9 MCAT Course Works" />
      <InPageNav
        pageTitle="How It Works"
        currentPath={location.pathname}
        navItems={[
          { link: "/mcat", label: "Overview" },
          { link: "/mcat/how_it_works", label: "How It Works" },
          { link: "/mcat/schedule", label: "Class Schedule" },
          { link: "/mcat/pricing", label: "Pricing" },
        ]}
        clickCallback={RedirectToPage}
      />
      <Section>
        <HeroTitleH1 align="center" float="center">
          HOW IT WORKS
        </HeroTitleH1>
      </Section>
      <br />
      <br />
      <br />
      <br />

      {howClassWorksContent.map((item, index) => {
        return (
          <Row
            key={"row" + item.title + item.description + index}
            wide
            backgroundColor="rgb(230,230,230)"
          >
            <RowContent
              ref={el => handleRefs(el, index)}
              className={"animatedRow " + index}
            >
              <div>
                <Title>{item.title}</Title>
                <SectionDescription>{item.description}</SectionDescription>
              </div>
              <RowItemWrap>
                <NumberTitle>{index + 1}</NumberTitle>
              </RowItemWrap>
            </RowContent>
          </Row>
        )
      })}
      <Accordian
        title={"Frequently Asked Questions"}
        accordianItems={[
          {
            heading: "Is the course online or in-person?",
            body:
              "Currently, because of student safety during the pandemic, all our classes are being held online.",
            imp: "good",
            accordianItems: [
              {
                heading:
                  "Has the pandemic negatively affected the quality of the courses being offered at 99point9?",
                body:
                  "It hasn't -- we started our delivering online classes several years before the pandemic unfortunately struck. Fortunately, we've been working towards creating an online experience that surpasses even in-person classes - so, at this point, our course is past the experimental stage.",
                imp: "neutral",
              },
              {
                heading:
                  "Has the pandemic negatively affected the quality of the courses being offered at 99point9?",
                body:
                  "It hasn't -- we started our delivering online classes several years before the pandemic unfortunately struck. Fortunately, we've been working towards creating an online experience that surpasses even in-person classes - so, at this point, our course is past the experimental stage.",
                imp: "neutral",
              },
            ],
          },
          {
            heading: "Does the online course have live lectures?",
            body:
              "No, they don't - but that's a good thing! We've seen that live lectures are great to get people excited but only at first, but within weeks they become very disengaging. More importantly though, they hinder us from fully customizing and personalizing your course in terms of content, scheduling, and feedback :) ",
            imp: "good",
          },
          {
            heading: "How does the score guarantee work?",
            body:
              "We've tried to keep things as un-business-like as possible, and offer options that we'd ourselves want. Simply, we understand that sometimes life happens and if we see that you're working hard but at the end you didn't score well -- you can retake the course free of charge!",
            imp: "good",
            accordianItems: [
              {
                heading: `What does it mean by "work hard"?`,
                body: `Our platform measures how long you're working, how focussed you are on your lecture, whether you're being neglecful in applying concepts, and how much you're studying each day (and more!). Our instructors also watch how engaged you are, and how often you're asking us questions. We determine whether you're working hard.`,
                imp: "neutral",
              },
            ],
          },
          {
            heading:
              "I haven't taken any (or some) of the science, will I be able to do well in the course?",
            body:
              "Yes, absolutely! We teach content from scratch, so you don't need to have any previous knowledge for any of the subjects. The MCAT is not solely a content test - it is very heavily based on the reasoning and implications; so much so, that our students can at times use just the reasoning & wording of the passages and the question to figure out the answers fast, without at times knowing the content completely (not that we endorse this approach).",
            imp: "good",
          },
          {
            heading:
              "I have a weird schedule. Can the MCAT course accommodate me?",
            body:
              "If it helps at all, we don't care when you're studying as long as you're putting in the requisite effort -- so, the course is completely flexible. Some of our students love studying through out the night because they focus better, and that's completely alright with us. ",
            imp: "good",
          },
          {
            heading:
              "What's uniquely better about the 99point9 MCAT compared to everyone else?",
            body:
              "Bottom-line: We make sure that you're studying, you're doing it the right way, and studying the way you will in med. school! We prepare you to learn content fast (not rushing though), and so well that when you get to the mock exam, recalling information is so easy! We also think like students, and prevent you from making mistakes in your prep. that would certainly tank your score. We don't just tell you what you need to do (because sometimes, you may know what to do, but you can't effectively do it) but we make sure that you're doing everytime.",
            imp: "good",
          },
        ]}
      />
    </Layout>
  )
}

export default HowItWorks
